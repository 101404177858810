import { Carrier } from '../../domain/carrier.types';
import { MeasurementUnitCode, Partner, Quantity } from '../../domain/common.types';
import { MeansOfTransport, MeansOfTransportWithoutConfirmationData } from '../../domain/meansOfTransport.types';
import { FreightForwarder, ManagedFreightForwarderUpdateRequest } from '../transportation/types';
import { ItemShortDescription, Party } from '../types';
import { Customs } from './customs.types';
import { HandlingUnitGroup } from './packaging.types';

export interface Address {
    street?: string;
    cityName?: string;
    countrySubEntityNameCode?: string;
    postalIdentificationCode?: string;
    countryNameCode?: string;
}

export interface PlaceOfDischarge {
    id: string;
    name?: string;
    address?: Address;
}

export interface AdditionalProductId {
    softwareStatus?: string;
    hardwareStatus?: string;
    partsGenerationStatus?: string;
}

export interface LoadItem {
    id: string;
    orderNumber: string;
    articleNumberBuyer: string;
    articleMasterData?: ArticleMasterData;
    amount: Quantity;
    batchNumber?: string;
    isSubjectToPreferenceAuthorization: boolean;
    expiryDate?: string;
    additionalProductId?: AdditionalProductId;
    referencedDeliveryScheduleId: string;
}

export interface DeliveryNote {
    deliveryNoteNumber: number;
    loadItems: LoadItem[];
}

export interface ShipmentCreationLoadItem {
    deliveryScheduleId: string;
    amount: Quantity;
}

export enum IncotermsCode {
    FCA = 'FCA',
    EXW = 'EXW',
    FOB = 'FOB',
    CFR = 'CFR',
    CIF = 'CIF',
    CIP = 'CIP',
    CPT = 'CPT',
    DAP = 'DAP',
    DDP = 'DDP',
    FAS = 'FAS',
    FOA = 'FOA',
    // Incoterms below are obsolete as of 2020
    DAF = 'DAF',
    DAT = 'DAT',
    DES = 'DES',
    DEQ = 'DEQ',
    DDU = 'DDU',
    FOR = 'FOR',
}

export interface TermsOfDelivery {
    incotermsCode?: IncotermsCode;
    location?: {
        name: string;
    };
}

export interface GroupOfIdenticalPackagingHierarchy {
    id: string;
    dimensions: Dimensions;
    mergeable: boolean;
    handlingUnitIds: string[];
}

export interface Dimensions {
    widthInMm?: number;
    lengthInMm?: number;
    heightInMm?: number;
}

export interface Shipment {
    id: string;
    partner: Partner;
    buyer: Party;
    shipmentNumber: number;
    shipToId: string;
    requestedDeliveryDate?: string;
    estimatedArrivalDate?: string;
    despatchDate?: string;
    dunsNumberOwner: string;
    placeOfDischarge: PlaceOfDischarge;
    load: DeliveryNote[];
    exported: boolean;
    exportedAt?: string;
    lastModifiedAt: string;
    packaging: HandlingUnitGroup[];
    groupOfIdenticalPackagingHierarchies: GroupOfIdenticalPackagingHierarchy[];
    termsOfDelivery?: TermsOfDelivery;
    freightForwarder?: FreightForwarder;
    carrier?: Carrier;
    customs?: Customs;
    meansOfTransport?: MeansOfTransport;
    transportOrderNumber?: string;
    netWeightInKg: number;
    grossWeightInKg: number;
    transportOrderReference?: string;
    creationOrigin: ShipmentCreationOrigin;
}

export enum ShipmentsQueryFor {
    SHIPMENT_NUMBER = 'SHIPMENT_NUMBER',
    DELIVERY_NOTE_NUMBER = 'DELIVERY_NOTE_NUMBER',
    ARTICLE_NUMBER = 'ARTICLE_NUMBER',
    LABEL_NUMBER = 'LABEL_NUMBER',
}

export interface ShipmentsFilterParams {
    plantNumber?: string;
    placeOfDischarge?: string;
    exportedAtFrom?: string;
    exportedAtTo?: string;
}

export interface ShipmentsQueryParams extends ShipmentsFilterParams {
    offset: number;
    limit: number;
    q?: string;
    queryFor: ShipmentsQueryFor;
}

export interface ShipmentsQuery {
    dunsNumber?: string;
    params: ShipmentsQueryParams;
}

export interface ShipmentsQueryResult {
    shipments: Shipment[];
    totalCountOfMatchedShipments: number;
    query: ShipmentsQueryParams;
}

export type ShipmentUpdateRequest = Omit<
    Shipment,
    | 'requestedDeliveryDate'
    | 'id'
    | 'partner'
    | 'buyer'
    | 'dunsNumberOwner'
    | 'placeOfDischarge'
    | 'exported'
    | 'shipToId'
    | 'freightForwarder'
    | 'lastModifiedAt'
    | 'netWeightInKg'
    | 'grossWeightInKg'
    | 'creationOrigin'
    | 'transportOrderReference'
    | 'meansOfTransport'
> &
    ManagedFreightForwarderUpdateRequest & {
        meansOfTransport?: MeansOfTransportWithoutConfirmationData;
    };

export interface ShipmentCreationRequest {
    requestedDeliveryDate?: string;
    estimatedArrivalDate?: string;
    dunsNumberOwner: string;
    placeOfDischarge: PlaceOfDischarge;
    load: ShipmentCreationLoadItem[];
}

export interface ArticleSuggestion {
    articleNumberBuyer: string;
    articleMasterData?: ArticleMasterData;
    itemShortDescriptions: ItemShortDescription[];
    orderNumber: string;
    measurementUnitCode: MeasurementUnitCode;
    referencedDeliveryScheduleId: string;
    hasValidArticleMasterData: boolean;
    hasValidCumulativeQuantitySent: boolean;
}

export interface ShipmentForm {
    outstandingArticleSuggestionForDeliveryNote?: ArticleSuggestionForDeliveryNote;
    loadItemPathForAdditionalPropertiesDialog?: string;
    isPackagingDialogVisible: boolean;
}

export interface ShipmentsState {
    selectedShipmentId?: string;
    nextAvailableDeliveryNoteNumber?: number;
    isLoadingShipments: boolean;
    savingShipmentStatus: SavingShipmentStatus;
    shipments: Shipment[];
    totalCountOfMatchedShipments: number;
    exportedQuery: ShipmentsQuery;
    notExportedQuery: ShipmentsQuery;
    exportedFilterValues: { [key: string]: string[] };
    notExportedFilterValues: { [key: string]: string[] };
    articleSuggestionSidebar: ArticleSuggestionSidebarState;
    form: ShipmentForm;
}

export enum SavingShipmentStatus {
    DEFAULT = 'DEFAULT',
    SAVING = 'SAVING',
    ERROR = 'ERROR',
}

export interface ArticleSuggestionSidebarState {
    open: boolean;
    referencedDeliveryNote?: number;
    articleSuggestions: ArticleSuggestion[];
}

export interface ArticleSuggestionForDeliveryNote {
    articleSuggestion: ArticleSuggestion;
    matchingDeliveryNoteNumber: number;
}

export interface DangerousGoods {
    unDangerousGoodsNumber: string;
    description?: string;
    declarationException?: string;
}

export interface ExtendedArticleMasterData extends ArticleMasterData {
    defaultShippingLocationId?: string;
    defaultPackagingTemplateId?: string;
}

export interface ArticleMasterData {
    articleNumberSeller?: string;
    dangerousGoods?: DangerousGoods;
    countryOfOrigin?: string;
    articleNetWeightInKg?: number;
    netExplosiveMassInKg?: number;
}

export interface DeliveryNoteNumberData {
    deliveryNoteNumber: number;
}

export interface LabelNumberData {
    labelNumber: number;
}

export enum ShipmentCreationOrigin {
    DISPATCH_PROPOSAL = 'DISPATCH_PROPOSAL',
    SHIPMENT_SUGGESTION = 'SHIPMENT_SUGGESTION',
}

export interface ShipmentsUpdatePayload {
    shipments: Shipment[];
    totalCountOfMatchedShipments: number;
}
