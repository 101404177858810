import { SelectOption } from '@rio-cloud/rio-uikit';
import SelectUiKit from '@rio-cloud/rio-uikit/lib/es/Select';
import { ReactNode } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { useDiscoveryNextGenFeatures } from '../../../../../configuration/featureToggle/hooks';
import { getValueOrDefaultWhenLoading } from '../../../../../configuration/featureToggle/utils';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/typedReduxHooks';
import { isStringNumeric, sanitize } from '../../../../../utils';
import { updateDeliveryScheduleDataAndRefresh } from '../../../actions/deliverySchedules/DeliverySchedules.actions';
import { MeasurementUnitCode } from '../../../domain/common.types';
import { useDunsNumberFromPath } from '../../../hooks/Routing.hooks';
import { deliverySchedulesSlice } from '../../../reducers/deliverySchedules/DeliverySchedules.reducer';
import {
    DeliverySchedule,
    DeliveryScheduleWorkflow,
    PackagingTemplate,
} from '../../../reducers/deliverySchedules/types';
import { LoadingLocation } from '../../../reducers/loadingLocations/types';
import { ExtendedArticleMasterData } from '../../../reducers/shipments/types';
import { getSelectedDeliverySchedulePackagingTemplates } from '../../../selectors/deliverySchedules/DeliverySchedules.selector';
import { MeasurementUnitCodeDisplay } from '../../common/MeasurementUnitCodeDisplay';
import { Tooltip } from '../../common/Tooltip';
import { CheckboxInput } from '../../common/form/CheckboxInput';
import { CountrySelectionField } from '../../common/form/CountrySelectionField';
import { NumberInput, getNumberInputParseFunction } from '../../common/form/NumberInput';
import { SwitchInput } from '../../common/form/SwitchInput';
import { TextAreaInput } from '../../common/form/TextAreaInput';
import { TextInput } from '../../common/form/TextInput';
import { FieldErrorType, FormErrors } from '../../common/form/types';
import { useLoadingLocationsQuery } from '../../loadingLocations/LoadingLocationHooks';
import { ArticleMasterDataFormProps } from '../types';

export const masterDataTestIds = {
    articleNumberSellerInput: 'ARTICLE_NUMBER_SELLER_INPUT',
    isDangerousGoodsInput: 'IS_DANGEROUS_GOODS_INPUT',
    dangerousGoodsNumberInput: 'DANGEROUS_GOODS_NUMBER_INPUT',
    dangerousGoodsDescriptionInput: 'DANGEROUS_GOODS_DESCRIPTION_INPUT',
    dangerousGoodsDeclarationExceptionInput: 'DANGEROUS_GOODS_DECLARATION_EXCEPTION_INPUT',
    countryOfOriginInput: 'COUNTRY_OF_ORIGIN_INPUT',
    articleNetWeightInput: 'ARTICLE_NET_WEIGHT_INPUT',
    netExplosiveMassInKgInput: 'NET_EXPLOSIVE_MASS_IN_KG_INPUT',
    articleMasterDataFormContent: 'ARTICLE_MASTER_DATA_FORM_CONTENT',
    defaultShippingLocation: 'DEFAULT_SHIPPING_LOCATION',
    includeDeliveryScheduleInDispatchProposalCreation: 'INCLUDE_DELIVERY_SCHEDULE_IN_DISPATCH_PROPOSAL_CREATION',
};

export const articleMasterDataFormId = 'ARTICLE_MASTER_DATA_FORM';
export const maxDangerousGoodsDescriptionsLength = 1280;
export const UnNumberLength = 4;

interface ArticleMasterDataFormValues {
    articleNumberSeller: string | undefined;
    isDangerousGoods: boolean;
    unDangerousGoodsNumber: string | undefined;
    dangerousGoodsDescription: string | undefined;
    dangerousGoodsDeclarationException: string | undefined;
    countryOfOrigin: string | undefined;
    articleNetWeightInKg: number | undefined;
    netExplosiveMassInKg: number | undefined;
    defaultShippingLocationId: string | undefined;
    defaultPackagingTemplateId: string | undefined;
    includeInDispatchProposalCreation: boolean;
}

type ArticleMasterDataFormErrors = FormErrors<ArticleMasterDataFormValues>;

const fieldNames: Record<string, keyof ArticleMasterDataFormValues> = {
    articleNumberSeller: 'articleNumberSeller',
    isDangerousGoods: 'isDangerousGoods',
    unDangerousGoodsNumber: 'unDangerousGoodsNumber',
    dangerousGoodsDescription: 'dangerousGoodsDescription',
    dangerousGoodsDeclarationException: 'dangerousGoodsDeclarationException',
    countryOfOrigin: 'countryOfOrigin',
    articleNetWeightInKg: 'articleNetWeightInKg',
    netExplosiveMassInKg: 'netExplosiveMassInKg',
    defaultShippingLocationId: 'defaultShippingLocationId',
    defaultPackagingTemplateId: 'defaultPackagingTemplateId',
    includeInDispatchProposalCreation: 'includeInDispatchProposalCreation',
};

const mapArticleMasterDataFormDataToExtendedArticleMasterData = (
    articleMasterDataFormData: ArticleMasterDataFormValues,
): ExtendedArticleMasterData => {
    if (articleMasterDataFormData.isDangerousGoods && !articleMasterDataFormData.unDangerousGoodsNumber) {
        throw new Error('Inconsistent form validation - isDangerousGoods requires a defined unDangerousGoodsNumber');
    }

    const dangerousGoods: ExtendedArticleMasterData['dangerousGoods'] | undefined =
        articleMasterDataFormData.isDangerousGoods
            ? {
                  unDangerousGoodsNumber: articleMasterDataFormData.unDangerousGoodsNumber!,
                  description: articleMasterDataFormData.dangerousGoodsDescription,
                  declarationException: articleMasterDataFormData.dangerousGoodsDeclarationException,
              }
            : undefined;

    return sanitize({
        articleNumberSeller: articleMasterDataFormData.articleNumberSeller,
        dangerousGoods,
        countryOfOrigin: articleMasterDataFormData.countryOfOrigin,
        articleNetWeightInKg: articleMasterDataFormData.articleNetWeightInKg,
        netExplosiveMassInKg: articleMasterDataFormData.netExplosiveMassInKg,
        defaultShippingLocationId: articleMasterDataFormData.defaultShippingLocationId,
        defaultPackagingTemplateId: articleMasterDataFormData.defaultPackagingTemplateId,
    });
};

const mapExtendedArticleMasterDataToArticleMasterDataFormData = (
    deliverySchedule: DeliverySchedule,
): ArticleMasterDataFormValues => {
    const articleMasterData = deliverySchedule.articleMasterData!;
    const dangerousGoods =
        articleMasterData.dangerousGoods !== undefined
            ? {
                  isDangerousGoods: true,
                  unDangerousGoodsNumber: articleMasterData.dangerousGoods.unDangerousGoodsNumber,
                  dangerousGoodsDescription: articleMasterData.dangerousGoods.description,
                  dangerousGoodsDeclarationException: articleMasterData.dangerousGoods.declarationException,
              }
            : {
                  isDangerousGoods: false,
                  unDangerousGoodsNumber: undefined,
                  dangerousGoodsDescription: undefined,
                  dangerousGoodsDeclarationException: undefined,
              };

    return {
        articleNumberSeller: articleMasterData.articleNumberSeller,
        ...dangerousGoods,
        countryOfOrigin: articleMasterData.countryOfOrigin,
        articleNetWeightInKg: articleMasterData.articleNetWeightInKg,
        netExplosiveMassInKg: articleMasterData.netExplosiveMassInKg,
        defaultShippingLocationId: articleMasterData.defaultShippingLocationId,
        defaultPackagingTemplateId: articleMasterData.defaultPackagingTemplateId,
        includeInDispatchProposalCreation: deliverySchedule.includeInDispatchProposalCreation,
    };
};

export const ArticleMasterDataForm = (props: ArticleMasterDataFormProps) => {
    const dispatch = useAppDispatch();
    const loadingLocations = useLoadingLocationsQuery().data ?? [];
    const dunsNumber = useDunsNumberFromPath() ?? '';

    const packagingTemplates = useAppSelector(getSelectedDeliverySchedulePackagingTemplates);
    const webSCMPlusFeaturesAreActive = getValueOrDefaultWhenLoading(useDiscoveryNextGenFeatures());
    const deliveryScheduleIsOfDiscoveryWorkflow =
        props.deliverySchedule.workflow === DeliveryScheduleWorkflow.DISCOVERY_NEXT_GEN;

    const onSubmitHandler = (formValues: ArticleMasterDataFormValues) => {
        dispatch(
            updateDeliveryScheduleDataAndRefresh({
                dunsNumber,
                deliveryScheduleId: props.deliverySchedule.id,
                articleMasterData: mapArticleMasterDataFormDataToExtendedArticleMasterData(formValues),
                includeInDispatchProposalCreation: formValues.includeInDispatchProposalCreation,
            }),
        );
    };

    const initialValues: ArticleMasterDataFormValues = props.deliverySchedule.articleMasterData
        ? mapExtendedArticleMasterDataToArticleMasterDataFormData(props.deliverySchedule)
        : {
              articleNumberSeller: undefined,
              isDangerousGoods: false,
              unDangerousGoodsNumber: undefined,
              dangerousGoodsDescription: undefined,
              dangerousGoodsDeclarationException: undefined,
              countryOfOrigin: undefined,
              articleNetWeightInKg: undefined,
              netExplosiveMassInKg: undefined,
              defaultShippingLocationId: undefined,
              defaultPackagingTemplateId: undefined,
              includeInDispatchProposalCreation: true,
          };

    const validateArticleMasterDataFormData = (formData: ArticleMasterDataFormValues): ArticleMasterDataFormErrors => {
        const returnObject: ArticleMasterDataFormErrors = {};
        if (formData.isDangerousGoods && !formData.unDangerousGoodsNumber) {
            returnObject.unDangerousGoodsNumber = FieldErrorType.REQUIRED;
        }
        if (
            formData.dangerousGoodsDescription &&
            formData.dangerousGoodsDescription.length > maxDangerousGoodsDescriptionsLength
        ) {
            returnObject.dangerousGoodsDescription = FieldErrorType.TOO_LONG;
        }
        if (
            formData.dangerousGoodsDeclarationException &&
            formData.dangerousGoodsDeclarationException.length > maxDangerousGoodsDescriptionsLength
        ) {
            returnObject.dangerousGoodsDeclarationException = FieldErrorType.TOO_LONG;
        }

        if (formData.unDangerousGoodsNumber && !isStringNumeric(formData.unDangerousGoodsNumber)) {
            returnObject.unDangerousGoodsNumber = FieldErrorType.CONTAINS_INVALID_CHARACTERS;
        }
        if (
            formData.unDangerousGoodsNumber &&
            isStringNumeric(formData.unDangerousGoodsNumber) &&
            formData.unDangerousGoodsNumber.length !== UnNumberLength
        ) {
            returnObject.unDangerousGoodsNumber = FieldErrorType.EXACT_LENGTH;
        }
        return returnObject;
    };

    const rowClassName = 'display-flex flex-row align-items-center text-color-dark width-100pct form-group margin-0';
    const articleMasterDataRowClassName = `${rowClassName} padding-10`;
    const dangerousGoodsRowClassName = `${rowClassName} padding-15`;
    const formInputClassName = 'width-70pct padding-right-10pct-md';
    const labelClassName = 'text-size-14 width-30pct padding-right-3pct';

    return (
        <div
            className={'padding-25 margin-left-2 margin-bottom-10'}
            data-testid={masterDataTestIds.articleMasterDataFormContent}
        >
            <Form<ArticleMasterDataFormValues>
                onSubmit={onSubmitHandler}
                initialValues={initialValues}
                validate={validateArticleMasterDataFormData}
                render={({ handleSubmit, values }) => {
                    const isArticleNetWeightInKgMissing =
                        values[fieldNames.articleNetWeightInKg] === undefined ||
                        values[fieldNames.articleNetWeightInKg] === '';
                    const isCountryOfOriginMissing =
                        values[fieldNames.countryOfOrigin] === undefined || values[fieldNames.countryOfOrigin] === '';

                    const measurementUnitCodeTranslation = (
                        <MeasurementUnitCodeDisplay
                            unit={props.deliverySchedule.scheduledArticleDetails?.measurementUnitCode}
                        />
                    );
                    return (
                        <form onSubmit={handleSubmit} id={articleMasterDataFormId}>
                            <div className={'display-flex flex-column margin-bottom-20'}>
                                <div className={'flex-1-1-0'}>
                                    <div className={`${articleMasterDataRowClassName} margin-bottom-15`}>
                                        <label className={labelClassName} htmlFor={fieldNames.articleNumberSeller}>
                                            <FormattedMessage id={'webedi.articleNumber.seller'} />
                                        </label>
                                        <div className={formInputClassName}>
                                            <Field
                                                className={'form-control'}
                                                id={fieldNames.articleNumberSeller}
                                                name={fieldNames.articleNumberSeller}
                                                data-testid={masterDataTestIds.articleNumberSellerInput}
                                                component={TextInput}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className={`${articleMasterDataRowClassName} ${isArticleNetWeightInKgMissing ? 'has-feedback has-warning' : ''}`}
                                    >
                                        <label
                                            className={`${labelClassName} padding-bottom-15`}
                                            htmlFor={fieldNames.articleNetWeightInKg}
                                        >
                                            <FormattedMessage
                                                id={'webedi.article.articleNetWeight.label'}
                                                values={{ measurementUnitCode: measurementUnitCodeTranslation }}
                                            />{' '}
                                            <Tooltip
                                                text={
                                                    <FormattedMessage id={'webedi.article.articleNetWeight.tooltip'} />
                                                }
                                                placement={'bottom'}
                                            >
                                                <span
                                                    className={
                                                        'rioglyph rioglyph-info-sign text-color-info text-size-18'
                                                    }
                                                />
                                            </Tooltip>
                                        </label>
                                        <div className={formInputClassName}>
                                            <Field<number>
                                                className={'form-control'}
                                                id={fieldNames.articleNetWeightInKg}
                                                name={fieldNames.articleNetWeightInKg}
                                                data-testid={masterDataTestIds.articleNetWeightInput}
                                                forbidScientificNotation
                                                min={0}
                                                warning={
                                                    isArticleNetWeightInKgMissing ? FieldErrorType.REQUIRED : undefined
                                                }
                                                component={NumberInput}
                                                step={0.000001}
                                                digitPrecision={6}
                                                measurementUnitCode={MeasurementUnitCode.KILOGRAM}
                                                parse={getNumberInputParseFunction(MeasurementUnitCode.KILOGRAM)}
                                            />
                                        </div>
                                    </div>
                                    {webSCMPlusFeaturesAreActive && (
                                        <div className={articleMasterDataRowClassName}>
                                            <label
                                                className={`${labelClassName} padding-bottom-15`}
                                                htmlFor={fieldNames.netExplosiveMassInKg}
                                            >
                                                <FormattedMessage
                                                    id={'webedi.article.netExplosiveMassInKg.label'}
                                                    values={{ measurementUnitCode: measurementUnitCodeTranslation }}
                                                />
                                            </label>
                                            <div className={formInputClassName}>
                                                <Field<number>
                                                    className={'form-control'}
                                                    id={fieldNames.netExplosiveMassInKg}
                                                    name={fieldNames.netExplosiveMassInKg}
                                                    forbidScientificNotation
                                                    min={0}
                                                    data-testid={masterDataTestIds.netExplosiveMassInKgInput}
                                                    component={NumberInput}
                                                    step={0.000001}
                                                    digitPrecision={6}
                                                    measurementUnitCode={MeasurementUnitCode.KILOGRAM}
                                                    parse={getNumberInputParseFunction(MeasurementUnitCode.KILOGRAM)}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    <div
                                        className={`${articleMasterDataRowClassName} ${isCountryOfOriginMissing ? 'has-feedback has-warning' : ''}`}
                                    >
                                        <label
                                            className={'width-30pct text-size-14 padding-bottom-15'}
                                            htmlFor={fieldNames.countryOfOrigin}
                                        >
                                            <FormattedMessage id={'webedi.article.countryOfOrigin'} />
                                        </label>
                                        <div
                                            data-testid={masterDataTestIds.countryOfOriginInput}
                                            className={formInputClassName}
                                        >
                                            <CountrySelectionField
                                                fieldName={fieldNames.countryOfOrigin}
                                                warning={isCountryOfOriginMissing ? FieldErrorType.REQUIRED : undefined}
                                            />
                                        </div>
                                    </div>
                                    {webSCMPlusFeaturesAreActive && (
                                        <div className={`${articleMasterDataRowClassName} margin-bottom-15`}>
                                            <label
                                                className={labelClassName}
                                                htmlFor={fieldNames.defaultShippingLocationId}
                                            >
                                                <FormattedMessage id={'webedi.article.defaultShippingLocation'} />
                                            </label>
                                            <div
                                                className={formInputClassName}
                                                data-testid={masterDataTestIds.defaultShippingLocation}
                                            >
                                                <LoadingLocationSelectionField
                                                    fieldName={fieldNames.defaultShippingLocationId}
                                                    loadingLocations={loadingLocations}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    <div className={`${articleMasterDataRowClassName} margin-bottom-15`}>
                                        <label
                                            className={labelClassName}
                                            htmlFor={fieldNames.defaultPackagingTemplateId}
                                        >
                                            <FormattedMessage id={'webedi.article.defaultPackagingTemplate'} />
                                        </label>
                                        <div className={formInputClassName}>
                                            <DefaultPackagingTemplateSelectionField
                                                fieldName={fieldNames.defaultPackagingTemplateId}
                                                packagingTemplates={Object.values(packagingTemplates)}
                                            />
                                        </div>
                                    </div>
                                    {deliveryScheduleIsOfDiscoveryWorkflow && (
                                        <div
                                            data-testid={
                                                masterDataTestIds.includeDeliveryScheduleInDispatchProposalCreation
                                            }
                                            className={`${articleMasterDataRowClassName} margin-bottom-15`}
                                        >
                                            <label
                                                className={labelClassName}
                                                htmlFor={fieldNames.includeInDispatchProposalCreation}
                                            >
                                                <FormattedMessage
                                                    id={'webedi.article.includeInDispatchProposalCreation.label'}
                                                />{' '}
                                                <Tooltip
                                                    text={
                                                        <FormattedMessage
                                                            id={
                                                                'webedi.article.includeInDispatchProposalCreation.tooltip.label'
                                                            }
                                                        />
                                                    }
                                                    placement={'bottom'}
                                                    width={300}
                                                >
                                                    <span
                                                        className={
                                                            'rioglyph rioglyph-info-sign text-color-info text-size-18'
                                                        }
                                                    />
                                                </Tooltip>
                                            </label>
                                            <div className={`${formInputClassName} margin-bottom-5`}>
                                                <Field<boolean>
                                                    id={fieldNames.includeInDispatchProposalCreation}
                                                    name={fieldNames.includeInDispatchProposalCreation}
                                                    component={SwitchInput}
                                                    type={'checkbox'}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    <div className={articleMasterDataRowClassName}>
                                        <label className={labelClassName} htmlFor={fieldNames.isDangerousGoods}>
                                            <FormattedMessage id={'webedi.article.isDangerousGoods'} />
                                        </label>
                                        <div
                                            className={`${formInputClassName} margin-bottom-5`}
                                            data-testid={masterDataTestIds.isDangerousGoodsInput}
                                        >
                                            <Field<boolean>
                                                id={fieldNames.isDangerousGoods}
                                                name={fieldNames.isDangerousGoods}
                                                component={CheckboxInput}
                                                type={'checkbox'}
                                            >
                                                <span className={'text-capitalize'}>
                                                    <FormattedMessage id={'webedi.yes'} />
                                                </span>
                                            </Field>
                                        </div>
                                    </div>
                                    <Condition when={'isDangerousGoods'} is={true}>
                                        <div className={'panel panel-default'}>
                                            <div className={'panel-heading'}>
                                                <strong>
                                                    <FormattedMessage id={'webedi.article.dangerousGoods'} />
                                                </strong>
                                            </div>
                                            <div className={'panel-body padding-right-0-md'}>
                                                <div className={dangerousGoodsRowClassName}>
                                                    <label
                                                        className={labelClassName}
                                                        htmlFor={fieldNames.unDangerousGoodsNumber}
                                                    >
                                                        <FormattedMessage
                                                            id={'webedi.article.dangerousGoods.unDangerousGoodsNumber'}
                                                        />
                                                        *
                                                    </label>
                                                    <div className={formInputClassName}>
                                                        <Field
                                                            className={'form-control'}
                                                            id={fieldNames.unDangerousGoodsNumber}
                                                            name={fieldNames.unDangerousGoodsNumber}
                                                            data-testid={masterDataTestIds.dangerousGoodsNumberInput}
                                                            component={TextInput}
                                                            exactStringLength={UnNumberLength}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={dangerousGoodsRowClassName}>
                                                    <label
                                                        className={labelClassName}
                                                        htmlFor={fieldNames.dangerousGoodsDescription}
                                                    >
                                                        <FormattedMessage
                                                            id={'webedi.article.dangerousGoods.description'}
                                                        />
                                                    </label>
                                                    <div className={formInputClassName}>
                                                        <Field
                                                            className={'form-control'}
                                                            id={fieldNames.dangerousGoodsDescription}
                                                            name={fieldNames.dangerousGoodsDescription}
                                                            data-testid={
                                                                masterDataTestIds.dangerousGoodsDescriptionInput
                                                            }
                                                            component={TextAreaInput}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={dangerousGoodsRowClassName}>
                                                    <label
                                                        className={labelClassName}
                                                        htmlFor={fieldNames.dangerousGoodsDeclarationException}
                                                    >
                                                        <FormattedMessage
                                                            id={'webedi.article.dangerousGoods.declarationException'}
                                                        />
                                                    </label>
                                                    <div className={formInputClassName}>
                                                        <Field
                                                            className={'form-control'}
                                                            id={fieldNames.dangerousGoodsDeclarationException}
                                                            name={fieldNames.dangerousGoodsDeclarationException}
                                                            data-testid={
                                                                masterDataTestIds.dangerousGoodsDeclarationExceptionInput
                                                            }
                                                            component={TextAreaInput}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Condition>
                                </div>
                            </div>
                            <FormSpy
                                subscription={{ dirty: true, modified: true }}
                                onChange={({ dirty, modified }) => {
                                    if (modified !== undefined && Object.keys(modified).length > 0) {
                                        dispatch(deliverySchedulesSlice.actions.setIsMetadataViewContentDirty(dirty));
                                    }
                                }}
                            />
                        </form>
                    );
                }}
            />
        </div>
    );
};

const Condition = <K extends keyof ArticleMasterDataFormValues>({
    when,
    is,
    children,
}: {
    when: K;
    is: ArticleMasterDataFormValues[K];
    children: ReactNode;
}) => (
    <Field name={when} subscription={{ value: true }}>
        {({ input: { value } }) => (value === is ? children : null)}
    </Field>
);

export interface LoadingLocationSelectionProps {
    fieldName: string;
    loadingLocations: LoadingLocation[];
}

const LoadingLocationSelectionField = (props: LoadingLocationSelectionProps) => {
    const { loadingLocations } = props;
    const options = loadingLocations.map((it) => ({ id: it.id, label: it.name }));

    return (
        <Field className={'form-control'} name={props.fieldName} component={'input'} type={'text'}>
            {(fieldProps) => (
                <SelectUiKit
                    useFilter={true}
                    id={props.fieldName}
                    onChange={(event?: SelectOption) => fieldProps.input.onChange(event?.id)}
                    options={options}
                    placeholder={<FormattedMessage id={'webedi.inputPlaceholder.dropdown.standard'} />}
                    value={fieldProps.input.value ? [fieldProps.input.value] : undefined}
                    useClear={true}
                />
            )}
        </Field>
    );
};

export interface DefaultPackagingTemplateSelectionProps {
    fieldName: string;
    packagingTemplates: PackagingTemplate[];
}

const DefaultPackagingTemplateSelectionField = (props: DefaultPackagingTemplateSelectionProps) => {
    const { packagingTemplates } = props;
    const options = packagingTemplates.map((it) => ({ id: it.id, label: it.name }));

    return (
        <Field className={'form-control'} name={props.fieldName} component={'input'} type={'text'}>
            {(fieldProps) => (
                <SelectUiKit
                    useFilter={true}
                    id={props.fieldName}
                    onChange={(event?: SelectOption) => fieldProps.input.onChange(event?.id)}
                    options={options}
                    placeholder={<FormattedMessage id={'webedi.inputPlaceholder.dropdown.standard'} />}
                    value={fieldProps.input.value ? [fieldProps.input.value] : undefined}
                    useClear={true}
                />
            )}
        </Field>
    );
};
