import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../../../configuration/setup/typedReduxHooks';
import {
    addArticleSuggestionForDeliveryNote,
    getArticleSuggestionsAction,
} from '../../../../actions/shipments/Shipments.actions';
import { useDunsNumberFromPath } from '../../../../hooks/Routing.hooks';
import { ArticleSuggestion } from '../../../../reducers/shipments/types';
import {
    getArticleSuggestions,
    getReferencedDeliveryNoteNumber,
    getSelectedShipmentId,
} from '../../../../selectors/shipments/Shipments.selector';
import { ArticleNumber } from '../../../common/ArticleNumber';
import { ItemShortDescription } from '../../../common/ItemShortDescription';
import { TextSearchFilter } from '../../../common/filter/TextSearchFilter';

import { ArticleSuggestionWarningIcon } from '../../../common/articleSuggestions/ArticleSuggestionWarningIcon';

const tableClassNames = ['table', 'table-column-overflow-hidden', 'table-head-filled', 'margin-0'].join(' ');

const ROW_ADD_ACTION_TEST_ID = 'ROW_ADD_ACTION_TEST_ID';
const ARTICLE_SUGGESTION_ROW_TABLE_TEST_ID = 'ARTICLE_SUGGESTION_ROW_TABLE_TEST_ID';

export const ArticleSuggestionTable = () => {
    const dispatch = useAppDispatch();
    const dunsNumber = useDunsNumberFromPath();
    const shipmentId = useAppSelector(getSelectedShipmentId);

    useEffect(() => {
        dispatch(getArticleSuggestionsAction(dunsNumber!, shipmentId!));
    }, [dispatch, dunsNumber, shipmentId]);
    const articleSuggestions = useAppSelector(getArticleSuggestions);

    return (
        <TextSearchFilter
            title={<FormattedMessage id={'webedi.deliverySchedule.searchBar.articleNumber'} />}
            values={articleSuggestions}
            getSearchableStrings={(value) =>
                value.itemShortDescriptions
                    .map((item) => item.itemDescription)
                    .concat([value.articleNumberBuyer, value.articleMasterData?.articleNumberSeller])
            }
            renderFiltered={(values) => <ShipmentSuggestionsTable items={values} />}
        />
    );
};

interface ShipmentSuggestionsTableProps {
    items: ArticleSuggestion[];
}

const ShipmentSuggestionsTable = (props: ShipmentSuggestionsTableProps) => {
    return (
        <div className={'table-responsive'}>
            <table className={tableClassNames}>
                <thead>
                    <tr>
                        <th className={'text-center'} />
                        <th>
                            <FormattedMessage id={'webedi.articleNumber.buyer'} />
                        </th>
                        <th>
                            <FormattedMessage id={'webedi.articleNumber.seller'} />
                        </th>
                        <th>
                            <FormattedMessage id={'webedi.itemShortDescription'} />
                        </th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {props.items.map((articleSuggestion: ArticleSuggestion) => (
                        <ArticleSuggestionRow
                            key={articleSuggestion.referencedDeliveryScheduleId}
                            articleSuggestion={articleSuggestion}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    );
};

interface ArticleSuggestionRowProps {
    articleSuggestion: ArticleSuggestion;
}

const ArticleSuggestionRow = (props: ArticleSuggestionRowProps) => {
    const dispatch = useAppDispatch();
    const referencedDeliveryNote = useAppSelector(getReferencedDeliveryNoteNumber);

    const { articleSuggestion } = props;

    const hasInvalidMasterData = !articleSuggestion.hasValidArticleMasterData;
    const hasInvalidCumulativeQuantitySent = !articleSuggestion.hasValidCumulativeQuantitySent;
    const hasWarnings = hasInvalidMasterData || hasInvalidCumulativeQuantitySent;

    return (
        <tr
            className={`${hasWarnings ? 'bg-lightest text-color-gray warning' : ''}`}
            key={articleSuggestion.articleNumberBuyer}
            data-testid={ARTICLE_SUGGESTION_ROW_TABLE_TEST_ID}
        >
            <td>
                <ArticleSuggestionWarningIcon
                    hasInvalidMasterData={hasInvalidMasterData}
                    hasInvalidCumulativeQuantitySent={hasInvalidCumulativeQuantitySent}
                />
            </td>
            <td className={'padding-left-20'}>
                <ArticleNumber articleNumber={articleSuggestion.articleNumberBuyer} />
            </td>
            <td>
                <ArticleNumber articleNumber={articleSuggestion.articleMasterData?.articleNumberSeller} />
            </td>
            <td>
                {articleSuggestion.itemShortDescriptions && (
                    <ItemShortDescription itemShortDescriptions={articleSuggestion.itemShortDescriptions} />
                )}
            </td>
            <td className={'padding-right-20'}>
                <button
                    type={'button'}
                    className={`btn btn-link btn-icon-only ${hasWarnings ? 'disabled' : ''}`}
                    data-testid={ROW_ADD_ACTION_TEST_ID}
                    onClick={() =>
                        referencedDeliveryNote &&
                        dispatch(
                            addArticleSuggestionForDeliveryNote({
                                articleSuggestion,
                                matchingDeliveryNoteNumber: referencedDeliveryNote,
                            }),
                        )
                    }
                >
                    <span className={'rioglyph rioglyph-plus text-size-14'} />
                </button>
            </td>
        </tr>
    );
};
