import { Navigate, Route, Routes } from 'react-router';
import { IdentityProvider, config } from '../../../../config';
import { useDunsNumberFromPath } from '../../hooks/Routing.hooks';
import { ClosingDaysView } from '../admin/closingDays/ClosingDaysView';
import { NotificationSettings } from '../admin/notifications/NotificationSettings';
import { UpsellingBanner } from '../common/UpsellingBanner';
import { DeliverySchedulesView } from '../deliverySchedules/DeliverySchedulesView';
import { DeliveryScheduleMetadataView } from '../deliverySchedules/deliveryScheduleMetadata/DeliveryScheduleMetadataView';
import { DispatchProposalsView } from '../dispatchProposals/DispatchProposalsView';
import { LoadingLocationsView } from '../loadingLocations/LoadingLocationsView';
import { ManagedFreightForwarderView } from '../managedFreightForwarders/ManagedFreightForwarderView';
import { Routing } from '../routing/Routes';
import { ShipmentView } from '../shipments/ShipmentView';
import { ShipmentsView } from '../shipments/ShipmentsView';
import { ShipmentSuggestionsView } from '../suggestions/ShipmentSuggestionsView';
import { TransportOrdersView } from '../transportOrders/TransportOrdersView';

export const WebScmView = (props: {
    webSCMPlusFeaturesAreActive: boolean;
}) => {
    const dunsNumber = useDunsNumberFromPath();
    const isRioEnvironment = config.identityProvider === IdentityProvider.RIO;
    const isVwEnvironment = config.identityProvider === IdentityProvider.VW;
    const isUpsellingBannerActive = isRioEnvironment || isVwEnvironment;
    return (
        <>
            <Routes>
                <Route
                    path={`/:dunsNumber${Routing.deliverySchedules}/:deliveryScheduleId${Routing.metadata}`}
                    element={<DeliveryScheduleMetadataView />}
                />
                <Route path={`/:dunsNumber${Routing.deliverySchedules}`} element={<DeliverySchedulesView />} />
                <Route path={`/:dunsNumber${Routing.suggestions}`} element={<ShipmentSuggestionsView />} />
                <Route path={`/:dunsNumber${Routing.shipments}/:shipmentId`} element={<ShipmentView />} />
                <Route path={`/:dunsNumber${Routing.exportedShipments}/:shipmentId`} element={<ShipmentView />} />
                <Route path={`/:dunsNumber${Routing.shipments}`} element={<ShipmentsView exported={false} />} />
                <Route path={`/:dunsNumber${Routing.exportedShipments}`} element={<ShipmentsView exported={true} />} />
                <Route path={`/:dunsNumber${Routing.carriers}`} element={<ManagedFreightForwarderView />} />
                <Route path={`/:dunsNumber${Routing.dispatchProposals}`} element={<DispatchProposalsView />} />
                <Route path={`/:dunsNumber${Routing.transportOrders}`} element={<TransportOrdersView />} />

                {props.webSCMPlusFeaturesAreActive && (
                    <Route
                        path={`/:dunsNumber${Routing.admin}${Routing.loadingLocations}`}
                        element={<LoadingLocationsView />}
                    />
                )}
                {props.webSCMPlusFeaturesAreActive && (
                    <Route path={`/:dunsNumber${Routing.admin}${Routing.closingDays}`} element={<ClosingDaysView />} />
                )}
                {(props.webSCMPlusFeaturesAreActive || isRioEnvironment) && (
                    <Route
                        path={`/:dunsNumber${Routing.admin}${Routing.carriers}`}
                        element={<ManagedFreightForwarderView />}
                    />
                )}
                {isRioEnvironment && (
                    <Route
                        path={`/:dunsNumber${Routing.admin}${Routing.notifications}`}
                        element={<NotificationSettings />}
                    />
                )}

                {props.webSCMPlusFeaturesAreActive && (
                    <Route
                        path={`/:dunsNumber${Routing.admin}`}
                        element={<Navigate to={`${Routing.webScm}/${dunsNumber}/admin${Routing.loadingLocations}`} />}
                    />
                )}
                {isRioEnvironment && (
                    <Route
                        path={`/:dunsNumber${Routing.admin}`}
                        element={<Navigate to={`${Routing.webScm}/${dunsNumber}/admin${Routing.carriers}`} />}
                    />
                )}
                <Route
                    path={'/:dunsNumber'}
                    element={<Navigate to={`${Routing.webScm}/${dunsNumber}${Routing.deliverySchedules}`} />}
                />
                <Route path={'*'} element={<Navigate to={Routing.dunsSelection} />} />
            </Routes>
            {isUpsellingBannerActive && <UpsellingBanner />}
        </>
    );
};
