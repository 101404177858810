import range from 'lodash/fp/range';
import { DateTime, DurationLike, SystemZone, Zone } from 'luxon';
import { DateObjectUnits } from 'luxon/src/datetime';
import { IntlShape } from 'react-intl/lib';
import { dateFormatProps, dateTimeFormatProps } from './features/app/components/common/i18n/FormattedDateOrDateTime';
import {
    ScheduledDate,
    ScheduledDateTime,
    ScheduledPeriod,
    isScheduledDate,
    isScheduledDateTime,
} from './features/app/reducers/deliverySchedules/types';

/* Checks */
export const isValidIso8601 = (date?: string): date is string => DateTime.fromISO(date ?? '').isValid;

export const isLocalDate = (dateTime?: string) => {
    if (dateTime === undefined || isValidIso8601(dateTime) === false) {
        return false;
    }
    return !dateTime.includes('T');
};

export const isLocalDateTime = (dateTime?: string) => {
    if (dateTime === undefined || isValidIso8601(dateTime) === false) {
        return false;
    }
    const timeSubstring = dateTime.substring(10);
    return (
        dateTime.includes('T') &&
        !dateTime.includes('Z') &&
        !timeSubstring.includes('+') &&
        !timeSubstring.includes('-')
    );
};

export const isToday = (date: string): boolean => {
    return areDaysEqual(date, today());
};

export const isTodayOrLater = (date: string): boolean => {
    return isToday(date) || parseIso(toLocalDate(date)) > parseIso(today());
};

export const areDaysEqual = (date1: string, date2: string): boolean => {
    return toLocalDate(date1) === toLocalDate(date2);
};

export const isAfter = (date1: string, date2: string): boolean => {
    return areDatesSameType(date1, date2) && parseIso(date1) > parseIso(date2);
};

export const isSameOrAfter = (date1: string, date2: string): boolean => {
    if (!areDatesSameType(date1, date2)) {
        return false;
    }
    const dateTime1 = parseIso(date1);
    const dateTime2 = parseIso(date2);
    return dateTime1 > dateTime2 || dateTime1.equals(dateTime2);
};

export const isBefore = (date1: string, date2: string): boolean => isAfter(date2, date1);

export const isSameOrBefore = (date1: string, date2: string): boolean => isSameOrAfter(date2, date1);

export const isNowBeforeNoon = (): boolean => DateTime.local().hour < 12;

/* Conversions */
export const dateTimeToLocalDate = (date: string): string =>
    DateTime.fromISO(date)
        .setZone(new SystemZone())
        .startOf('day')
        .setZone(localDateZone, { keepLocalTime: true })
        .toFormat('yyyy-MM-dd');

export const localDateToLocalStartOfDayInUtc = (date: string): string =>
    DateTime.fromISO(date, { zone: new SystemZone() })
        .startOf('day')
        .setZone('UTC')
        .toISO({ suppressMilliseconds: true }) || '';

export const localDateToLocalEndOfDayInUtc = (date: string): string =>
    DateTime.fromISO(date, { zone: new SystemZone() })
        .endOf('day')
        .setZone('UTC')
        .toISO({ suppressMilliseconds: true }) || '';

export const toLocalDate = (dateTime: string): string => parseIso(dateTime).toFormat('yyyy-MM-dd');

export const toLocalDateTime = (dateTime: string): string => parseIso(dateTime).toFormat(`yyyy-MM-dd'T'HH:mm:ss`);

export const toLocalTime = (dateTime: string): string => parseIso(dateTime).toFormat('HH:mm');

export const toUtcDateTime = (dateTime: string): string =>
    parseIso(dateTime).setZone('UTC').toISO({ suppressMilliseconds: true })!;

export const toZonedDateTime = (dateTime: string, zone: string): string => {
    const keepLocalTime = isLocalDate(dateTime) || isLocalDateTime(dateTime);
    return parseIso(dateTime).setZone(zone, { keepLocalTime }).toISO({ suppressMilliseconds: true })!;
};

export const toMillis = (dateTime: string): number => DateTime.fromISO(dateTime).toMillis();

/* Mutations */
export const plusDuration = (dateTime: string, duration: DurationLike): string =>
    dateTimeToIsoStringDate(parseIso(dateTime).plus(duration));

export const minusDuration = (dateTime: string, duration: DurationLike): string =>
    dateTimeToIsoStringDate(parseIso(dateTime).minus(duration));

export const startOfWeek = (dateTime: string): string => dateTimeToIsoStringDate(parseIso(dateTime).startOf('week'));

export const setComponents = (dateTime: string, components: DateObjectUnits): string =>
    dateTimeToIsoStringDate(parseIso(dateTime).set(components));

export const roundedToNextHalfHour = (dateTime: string): string => {
    const dateTimeObject = parseIso(dateTime).minus({ milliseconds: 1 });
    return dateTimeObject.minute >= 30
        ? dateTimeToIsoStringDate(dateTimeObject.plus({ hours: 1 }).startOf('hour'))
        : dateTimeToIsoStringDate(dateTimeObject.set({ minute: 30 }).startOf('minute'));
};

/* Generators */
export const listOfDaysStartingAt = (date: string, numberOfDays: number): string[] => {
    const startDate = toLocalDate(date);
    return range(0, numberOfDays).map((day) => plusDuration(startDate, { days: day }));
};

export const listOfCalendarWeeksStartingAt = (date: string, numberOfWeeks: number): string[] => {
    const startDate = dateTimeToIsoStringDate(parseIso(toLocalDate(date)).startOf('week'));
    return range(0, numberOfWeeks).map((week) => plusDuration(startDate, { weeks: week }));
};

export const localNow = (): string => DateTime.local().toFormat(`yyyy-MM-dd'T'HH:mm:ss`);
export const localZonedNow = (): string => DateTime.local().toISO({ suppressMilliseconds: true })!;

export const utcNow = (): string => DateTime.utc().toISO({ suppressMilliseconds: true })!;

export const today = (): string => DateTime.local().toFormat('yyyy-MM-dd');

export const localTimeZone = (): string => DateTime.local().zoneName;

/* Formatters */
export const formatDateOrDateTime = (date: string | undefined, intl: IntlShape, isZoned: boolean = false): string => {
    if (date === undefined) {
        return '';
    }
    return isLocalDate(date)
        ? intl.formatDate(date, { ...dateFormatProps, timeZone: 'UTC' })
        : intl.formatDate(isZoned ? toLocalDateTime(date) : date, dateTimeFormatProps);
};

export const formatWeekday = (date: string, intl: IntlShape) => {
    return intl.formatDate(date, { weekday: 'long', timeZone: isLocalDate(date) ? 'UTC' : undefined });
};

export const formatScheduledDateTimeOrPeriod = (
    datetimeOrPeriod: ScheduledDate | ScheduledDateTime | ScheduledPeriod,
    intl: IntlShape,
): string => {
    if (isScheduledDate(datetimeOrPeriod)) {
        return formatDateOrDateTime(datetimeOrPeriod.scheduledDate, intl);
    }
    if (isScheduledDateTime(datetimeOrPeriod)) {
        return formatDateOrDateTime(datetimeOrPeriod.scheduledDateTime, intl);
    }
    return `${formatDateOrDateTime(datetimeOrPeriod.earliestDeliveryDate, intl)} - ${formatDateOrDateTime(datetimeOrPeriod.latestDeliveryDate, intl)}`;
};

/* Helpers */

const parseIso = (isoString: string): DateTime => {
    if (isLocalDate(isoString)) {
        return DateTime.fromISO(isoString, { zone: localDateZone });
    } else if (isLocalDateTime(isoString)) {
        return DateTime.fromISO(`${isoString}`, { zone: localDateTimeZone });
    } else {
        return DateTime.fromISO(isoString, { setZone: true });
    }
};

const dateTimeToIsoStringDate = (date: DateTime): string => {
    if (date.zone.equals(localDateZone)) {
        return date.toFormat('yyyy-MM-dd');
    } else if (date.zone.equals(localDateTimeZone)) {
        return date.toFormat(`yyyy-MM-dd'T'HH:mm:ss`);
    } else {
        return date.toISO({ suppressMilliseconds: true }) || '';
    }
};

const areDatesSameType = (date1: string, date2: string): boolean =>
    isValidIso8601(date1) &&
    isValidIso8601(date2) &&
    isLocalDate(date1) === isLocalDate(date2) &&
    isLocalDateTime(date1) === isLocalDateTime(date2);

class CustomZone extends Zone {
    zoneName: string;
    constructor(name: string) {
        super();
        this.zoneName = name;
    }
    get type() {
        return 'custom';
    }
    get name() {
        return this.zoneName;
    }
    get ianaName() {
        return `Etc/${this.zoneName}`;
    }
    get isUniversal() {
        return true;
    }
    offsetName() {
        return this.name;
    }
    formatOffset(_: number, __: string) {
        return '+00:00';
    }
    offset() {
        return 0;
    }
    equals(otherZone: Zone) {
        return otherZone.type === 'custom' && otherZone.name === this.name;
    }
    get isValid() {
        return true;
    }
}

const localDateTimeZone = new CustomZone('localDateTime');
const localDateZone = new CustomZone('localDate');
