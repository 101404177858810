import { LogLevel } from 'configcat-common/lib/ConfigCatLogger';
import { InMemoryWebStorage, WebStorageStateStore } from 'oidc-client-ts';
import { neverReachedFor } from './utils';

export enum IdentityProvider {
    RIO = 'rio',
    VW_QA = 'vw-qa',
    VW = 'vw',
}

export const getCurrentIdentityProvider = (hostname: string): IdentityProvider => {
    if (hostname.startsWith(IdentityProvider.VW_QA)) {
        return IdentityProvider.VW_QA;
    }
    if (hostname.startsWith(IdentityProvider.VW)) {
        return IdentityProvider.VW;
    }
    if (import.meta.env.DEV) {
        if (hostname.startsWith('rio.localhost')) {
            return IdentityProvider.RIO;
        }
        if (hostname.startsWith('vw.localhost')) {
            return IdentityProvider.VW;
        }
    }
    return IdentityProvider.RIO;
};

export interface ConfigState {
    versionNumber: number;
    featureToggles: {
        configCatApiKey: string;
        logLevel: LogLevel;
        cacheTimeToLiveSeconds: number;
    };
    id: string | undefined;
    login: {
        authority: string | undefined;
        clientId: string | undefined;
        oauthScope: string[];
        mockAuthorization: boolean;
        mockLocale: string | undefined;
        preventRedirect: boolean;
        redirectUri: string | undefined;
        silentRedirectUri: string | undefined;
        monitorSession?: boolean;
        mobilePhone?: string;
        userStore?: WebStorageStateStore;
    };
    postLogoutRedirectUri: string | undefined;
    sentryToken: string | undefined;
    webEdiServiceUrl: string | undefined;
    emailNotificationServiceUrl: string | undefined;
    identityProvider: IdentityProvider | undefined;
    closingDaysUrl: string;
    uikitCssUrl?: string;
    htmlTitle: string;
}

const asBool = (value: string | undefined): boolean => value === 'true';

const mapToConfigCatLogLevel = (logLevel: string | undefined): LogLevel => {
    switch (logLevel?.toUpperCase()) {
        case 'INFO':
            return LogLevel.Info;
        case 'WARN':
            return LogLevel.Warn;
        case 'ERROR':
            return LogLevel.Error;
        default:
            return LogLevel.Off;
    }
};

export const config: ConfigState = {
    versionNumber: 7,
    featureToggles: {
        configCatApiKey: import.meta.env.VITE_CONFIG_CAT_API_KEY || 'unavailable', //empty string crashes the app
        logLevel: mapToConfigCatLogLevel(import.meta.env.VITE_CONFIG_CAT_LOG_LEVEL),
        cacheTimeToLiveSeconds: 600,
    },
    id: import.meta.env.VITE_ID,
    login: {
        authority: import.meta.env.VITE_LOGIN_AUTHORITY,
        clientId: import.meta.env.VITE_CLIENT_ID,
        redirectUri: import.meta.env.VITE_LOGIN_REDIRECT_URI,
        silentRedirectUri: import.meta.env.VITE_LOGIN_SILENT_REDIRECT_URI,
        oauthScope: ['openid', 'profile', 'email'],
        mockAuthorization: asBool(import.meta.env.VITE_LOGIN_MOCK_AUTHORIZATION),
        mockLocale: import.meta.env.VITE_LOGIN_MOCK_LOCALE,
        preventRedirect: asBool(import.meta.env.VITE_LOGIN_PREVENT_REDIRECT),
    },
    postLogoutRedirectUri: import.meta.env.VITE_LOGOUT_URI,
    sentryToken: import.meta.env.VITE_SENTRY_DSN,
    webEdiServiceUrl: import.meta.env.VITE_WEBEDI_SERVICE,
    emailNotificationServiceUrl: import.meta.env.VITE_EMAIL_NOTIFICATION_SERVICE,
    identityProvider: undefined,
    closingDaysUrl: import.meta.env.VITE_CLOSING_DAYS_FRONTEND_RIO,
    htmlTitle: import.meta.env.VITE_VW_HTML_TITLE,
};

export const applyConfigForIdp = () => {
    const idp = getCurrentIdentityProvider(window.location.hostname);
    switch (idp) {
        case IdentityProvider.RIO:
            config.login.authority = import.meta.env.VITE_LOGIN_AUTHORITY;
            config.login.clientId = import.meta.env.VITE_CLIENT_ID;
            config.login.redirectUri = import.meta.env.VITE_LOGIN_REDIRECT_URI;
            config.login.silentRedirectUri = import.meta.env.VITE_LOGIN_SILENT_REDIRECT_URI;
            config.login.userStore = new WebStorageStateStore({ store: new InMemoryWebStorage() });
            config.postLogoutRedirectUri = import.meta.env.VITE_POST_LOGOUT_REDIRECT_URI;
            config.login.oauthScope = [
                'openid',
                'profile',
                'email',
                'webedi-notification-settings.read',
                'webedi-notification-settings.write',
            ];
            config.featureToggles.configCatApiKey = import.meta.env.VITE_CONFIG_CAT_API_KEY || 'unavailable';
            config.identityProvider = IdentityProvider.RIO;
            config.closingDaysUrl = import.meta.env.VITE_CLOSING_DAYS_FRONTEND_RIO;
            config.uikitCssUrl = import.meta.env.VITE_RIO_UIKIT_THEME_URL;
            config.htmlTitle = import.meta.env.VITE_RIO_HTML_TITLE;
            break;
        case IdentityProvider.VW_QA:
            config.login.authority = import.meta.env.VITE_LOGIN_AUTHORITY_VW_QA;
            config.login.clientId = import.meta.env.VITE_CLIENT_ID_VW_QA;
            config.login.redirectUri = import.meta.env.VITE_LOGIN_REDIRECT_URI_VW_QA;
            config.login.silentRedirectUri = import.meta.env.VITE_LOGIN_SILENT_REDIRECT_URI_VW_QA;
            config.postLogoutRedirectUri = import.meta.env.VITE_LOGOUT_URI_VW_QA;
            config.login.oauthScope = ['offline_access', 'openid'];
            config.login.monitorSession = false;
            config.featureToggles.configCatApiKey = import.meta.env.VITE_CONFIG_CAT_API_KEY_VW_QA || 'unavailable';
            config.identityProvider = IdentityProvider.VW_QA;
            config.closingDaysUrl = import.meta.env.VITE_CLOSING_DAYS_FRONTEND_VW_QA;
            config.uikitCssUrl = import.meta.env.VITE_VW_UIKIT_THEME_URL;
            config.htmlTitle = import.meta.env.VITE_VW_HTML_TITLE;
            break;
        case IdentityProvider.VW:
            config.login.authority = import.meta.env.VITE_LOGIN_AUTHORITY_VW;
            config.login.clientId = import.meta.env.VITE_CLIENT_ID_VW;
            config.login.redirectUri = import.meta.env.VITE_LOGIN_REDIRECT_URI_VW;
            config.login.silentRedirectUri = import.meta.env.VITE_LOGIN_SILENT_REDIRECT_URI_VW;
            config.postLogoutRedirectUri = import.meta.env.VITE_LOGOUT_URI_VW;
            config.login.oauthScope = ['offline_access', 'openid'];
            config.login.monitorSession = false;
            config.featureToggles.configCatApiKey = import.meta.env.VITE_CONFIG_CAT_API_KEY_VW || 'unavailable';
            config.identityProvider = IdentityProvider.VW;
            config.closingDaysUrl = import.meta.env.VITE_CLOSING_DAYS_FRONTEND_VW;
            config.uikitCssUrl = import.meta.env.VITE_VW_UIKIT_THEME_URL;
            config.htmlTitle = import.meta.env.VITE_VW_HTML_TITLE;
            break;
        default:
            neverReachedFor(idp);
    }
};
