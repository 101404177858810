import { ConfigCatProvider, DataGovernance, PollingMode, createConsoleLogger } from 'configcat-react';
import { ReactNode } from 'react';
import { config } from '../../config';

export const FeatureToggleProvider = (props: { children?: ReactNode }) => {
    return (
        <ConfigCatProvider
            sdkKey={config.featureToggles.configCatApiKey}
            pollingMode={PollingMode.LazyLoad}
            options={{
                cacheTimeToLiveSeconds: config.featureToggles.cacheTimeToLiveSeconds,
                logger: createConsoleLogger(config.featureToggles.logLevel),
                dataGovernance: DataGovernance.EuOnly,
            }}
        >
            {props.children}
        </ConfigCatProvider>
    );
};
