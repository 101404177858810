import { FormattedMessage } from 'react-intl';

interface Props {
    errorId?: string;
}
export const CourierOrderIntentErrorBanner = (props: Props) => {
    const mapOfKnownErrorCodes: Record<string, string> = {
        UPS_120217: 'webedi.dispatchProposals.expressParcel.dialog.errors.Ups120217',
        DHL_202062: 'webedi.dispatchProposals.expressParcel.dialog.errors.Dhl202062',
        DHL_202113: 'webedi.dispatchProposals.expressParcel.dialog.errors.Dhl202113',
        DHL_5005: 'webedi.dispatchProposals.expressParcel.dialog.errors.commonServiceError',
        DHL_5001: 'webedi.dispatchProposals.expressParcel.dialog.errors.commonServiceError',
        DHL_CUSTOM_PARCEL_WEIGHT_EXCEEDED:
            'webedi.dispatchProposals.expressParcel.dialog.errors.DHL_CUSTOM_PARCEL_WEIGHT_EXCEEDED',
        DHL_CUSTOM_PARCEL_TOTAL_WEIGHT_EXCEEDED:
            'webedi.dispatchProposals.expressParcel.dialog.errors.DHL_CUSTOM_PARCEL_TOTAL_WEIGHT_EXCEEDED',
        DHL_CUSTOM_PARCEL_LENGTH_EXCEEDED:
            'webedi.dispatchProposals.expressParcel.dialog.errors.DHL_CUSTOM_PARCEL_LENGTH_EXCEEDED',
        DHL_CUSTOM_PARCEL_WIDTH_EXCEEDED:
            'webedi.dispatchProposals.expressParcel.dialog.errors.DHL_CUSTOM_PARCEL_WIDTH_EXCEEDED',
        DHL_CUSTOM_PARCEL_HEIGHT_EXCEEDED:
            'webedi.dispatchProposals.expressParcel.dialog.errors.DHL_CUSTOM_PARCEL_HEIGHT_EXCEEDED',
        UPS_9510122: 'webedi.dispatchProposals.expressParcel.dialog.errors.commonServiceError',
        UPS_9510114: 'webedi.dispatchProposals.expressParcel.dialog.errors.commonServiceError',
        INVALID_OUTER_DIMENSIONS: 'webedi.error.INVALID_OUTER_DIMENSIONS',
    };

    const mappedOrGenericErrorId =
        mapOfKnownErrorCodes[props.errorId || ''] ??
        'webedi.dispatchProposals.expressParcel.dialog.errors.genericError';

    return (
        <div className='display-flex flex-column align-items-center padding-x-5pct' style={{ width: '100%' }}>
            <div className={`alert alert-danger margin-bottom-25`} style={{ width: '100%' }}>
                <div className={'display-flex'}>
                    <span className={`text-color-danger text-size-h4 margin-right-10 rioglyph rioglyph-error-sign`} />
                    <div>
                        <div className={'text-color-danger text-bold margin-bottom-10 last-child-margin-bottom-0'}>
                            <FormattedMessage id={mappedOrGenericErrorId} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
