import _ from 'lodash';
import { Carrier } from '../../domain/carrier.types';
import { Quantity } from '../../domain/common.types';
import {
    ArticleMasterData,
    DangerousGoods,
    Dimensions,
    ExtendedArticleMasterData,
} from '../../reducers/shipments/types';
import { ApiArticleMasterData, ApiDangerousGoods, ApiExtendedArticleMasterData } from './articleMasterData.types';
import { ApiCarrier } from './carrier.types';
import { ApiDimensions } from './dimensions.types';
import { ApiQuantity } from './quantity.types';

export const mapDangerousGoods = (apiDangerousGoods: ApiDangerousGoods): DangerousGoods => {
    return {
        unDangerousGoodsNumber: apiDangerousGoods.un_dangerous_goods_number,
        description: apiDangerousGoods.description,
        declarationException: apiDangerousGoods.declaration_exception,
    };
};

export const mapArticleMasterData = (apiArticleMasterData: ApiArticleMasterData): ArticleMasterData => {
    return {
        articleNumberSeller: apiArticleMasterData.article_number_seller,
        dangerousGoods: apiArticleMasterData.dangerous_goods
            ? mapDangerousGoods(apiArticleMasterData.dangerous_goods)
            : undefined,
        countryOfOrigin: apiArticleMasterData.country_of_origin,
        articleNetWeightInKg: apiArticleMasterData.article_net_weight_in_kg,
        netExplosiveMassInKg: apiArticleMasterData.net_explosive_mass_in_kg,
    };
};

export const mapExtendedArticleMasterData = (
    apiExtendedArticleMasterData: ApiExtendedArticleMasterData,
): ExtendedArticleMasterData => {
    const { default_shipping_location_id, default_packaging_template_id, ...apiArticleMasterData } =
        apiExtendedArticleMasterData;
    return {
        ...mapArticleMasterData(apiArticleMasterData),
        defaultShippingLocationId: default_shipping_location_id,
        defaultPackagingTemplateId: default_packaging_template_id,
    };
};

export const mapDimensions = (apiDimensions: ApiDimensions): Dimensions => {
    return {
        widthInMm: apiDimensions.width_in_mm,
        lengthInMm: apiDimensions.length_in_mm,
        heightInMm: apiDimensions.height_in_mm,
    };
};

export const mapDangerousGoodsToApi = (dangerousGoods: DangerousGoods): ApiDangerousGoods => {
    return {
        un_dangerous_goods_number: dangerousGoods.unDangerousGoodsNumber,
        description: dangerousGoods.description,
        declaration_exception: dangerousGoods.declarationException,
    };
};

export const mapArticleMasterDataToApi = (articleMasterData: ArticleMasterData): ApiArticleMasterData => {
    return {
        article_number_seller: articleMasterData.articleNumberSeller,
        dangerous_goods: articleMasterData.dangerousGoods
            ? mapDangerousGoodsToApi(articleMasterData.dangerousGoods)
            : undefined,
        country_of_origin: articleMasterData.countryOfOrigin,
        article_net_weight_in_kg: articleMasterData.articleNetWeightInKg,
        net_explosive_mass_in_kg: articleMasterData.netExplosiveMassInKg,
    };
};

export const mapExtendedArticleMasterDataToApi = (
    extendedArticleMasterData: ExtendedArticleMasterData,
): ApiExtendedArticleMasterData => {
    const { defaultShippingLocationId, defaultPackagingTemplateId, ...articleMasterData } = extendedArticleMasterData;
    return {
        ...mapArticleMasterDataToApi(articleMasterData),
        default_shipping_location_id: defaultShippingLocationId,
        default_packaging_template_id: defaultPackagingTemplateId,
    };
};

export const mapDimensionsToApi = (dimensions?: Dimensions): ApiDimensions => {
    if (!dimensions) {
        return {
            width_in_mm: undefined,
            length_in_mm: undefined,
            height_in_mm: undefined,
        };
    } else {
        return {
            width_in_mm: dimensions.widthInMm,
            length_in_mm: dimensions.lengthInMm,
            height_in_mm: dimensions.heightInMm,
        };
    }
};

export const mapToCarrier = (carrier: ApiCarrier): Carrier => ({
    name: carrier.name,
    dunsNumber: carrier.duns_number,
});
export const mapQuantity = (apiQuantity: ApiQuantity): Quantity => {
    return {
        value: apiQuantity.value,
        measurementUnitCode: apiQuantity.measurement_unit_code,
    };
};
export const mapQuantityToApi = (quantity: Quantity): ApiQuantity => ({
    value: quantity.value,
    measurement_unit_code: quantity.measurementUnitCode,
});

type SupportedQueryParamTypes = string | number | boolean | string[] | undefined;

export const toUrlParam = (param: SupportedQueryParamTypes) => {
    const value = param;
    if (value === undefined) {
        return undefined;
    }
    if (value instanceof Array) {
        return value.map((item) => encodeURIComponent(item)).join(',');
    }
    return encodeURIComponent(value);
};

export const buildQueryParametersSuffix = <T extends SupportedQueryParamTypes>(params: { [s: string]: T }): string => {
    let result = '';

    Object.entries(params).forEach(([key, param]) => {
        const encodedValue = toUrlParam(param);
        const keyInSnakeCase = _.snakeCase(key);
        if (encodedValue !== undefined) {
            const delimiter = result === '' ? '?' : '&';
            result += `${delimiter}${keyInSnakeCase}=${encodedValue}`;
        }
    });
    return result;
};
