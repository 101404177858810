import { Log } from 'oidc-client-ts';

const handleLoginRedirect = (userManager) => {
    const runsInIframe = window?.parent && window.parent !== window;

    Log.setLogger(console);
    Log.setLevel(Log.WARN);

    if (runsInIframe) {
        // Silent redirect within an <iframe>

        // This will propagate the received information provided via
        // query parameters to the parent frame
        // biome-ignore lint/nursery/noConsole: Only for debugging
        console.debug('signinRedirect via the iframe');
        return userManager.signinSilentCallback();
    } else {
        // biome-ignore lint/nursery/noConsole: Only for debugging
        console.debug('signinRedirect via the parent window');
        return userManager
            .signinRedirectCallback()
            .catch(console.log)
            .then(() => {
                window.history.replaceState(null, '', window.location.origin);
            });
    }
};

export default handleLoginRedirect;
