export interface FeatureToggleResponse<T> {
    value: T | undefined;
    defaultValue: T;
    isLoading: boolean;
}

export const getValueOrDefaultWhenLoading = <T>(featureToggleResponse: FeatureToggleResponse<T>): T =>
    featureToggleResponse.isLoading
        ? featureToggleResponse.defaultValue
        : featureToggleResponse.value ?? featureToggleResponse.defaultValue;

export const featureToggleOn: FeatureToggleResponse<boolean> = { defaultValue: true, isLoading: false, value: true };
export const featureToggleOff: FeatureToggleResponse<boolean> = { defaultValue: false, isLoading: false, value: false };
