// biome-ignore lint/style/noNamespaceImport: Prefer prefix because of shadowing from io-ts
import * as t from 'io-ts';

export const DangerousGoodsCodec = t.intersection([
    t.type({
        un_dangerous_goods_number: t.string,
    }),
    t.partial({
        description: t.string,
        declaration_exception: t.string,
    }),
]);

export const ArticleMasterDataCodec = t.partial({
    article_number_seller: t.string,
    dangerous_goods: DangerousGoodsCodec,
    country_of_origin: t.string,
    article_net_weight_in_kg: t.number,
    net_explosive_mass_in_kg: t.number,
});

export const ExtendedArticleMasterDataCodec = t.intersection([
    ArticleMasterDataCodec,
    t.partial({
        default_shipping_location_id: t.string,
        default_packaging_template_id: t.string,
    }),
]);

export type ApiArticleMasterData = t.TypeOf<typeof ArticleMasterDataCodec>;
export type ApiExtendedArticleMasterData = t.TypeOf<typeof ExtendedArticleMasterDataCodec>;
export type ApiDangerousGoods = t.TypeOf<typeof DangerousGoodsCodec>;
