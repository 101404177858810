import { User, useFeatureFlag } from 'configcat-react';
import { useEffect, useState } from 'react';
import { config } from '../../config';
import { useDunsNumberFromPath } from '../../features/app/hooks/Routing.hooks';
import { getIdToken } from '../index';
import { useAppSelector } from '../setup/typedReduxHooks';
import { FeatureToggleResponse } from './utils';

enum FeatureToggle {
    WEBEDI_REFRESH_VERSION_NUMBER = 'WEBEDI_REFRESH_VERSION_NUMBER',
    DISCOVERY_NEXT_GEN_FEATURES = 'DISCOVERY_NEXT_GEN_FEATURES',
    WORK_IN_PROGRESS = 'WORK_IN_PROGRESS',
    MAINTENANCE_WINDOW = 'MAINTENANCE_WINDOW',
}

const defaultValues: Record<FeatureToggle, boolean | number | string> = {
    [FeatureToggle.WEBEDI_REFRESH_VERSION_NUMBER]: config.versionNumber,
    [FeatureToggle.DISCOVERY_NEXT_GEN_FEATURES]: false,
    [FeatureToggle.WORK_IN_PROGRESS]: false,
    [FeatureToggle.MAINTENANCE_WINDOW]: 'DISABLED',
};

const IDENTIFIER_FOR_UNKNOWN_USERS = 'anonymous';

const hashSha512 = async (message: string) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(message);
    const hashBuffer = await crypto.subtle.digest('SHA-512', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
};

const useSha512HashedString = (message: string | undefined): string | undefined => {
    const [result, setResult] = useState<string | undefined>(undefined);
    useEffect(() => {
        if (message !== undefined) {
            hashSha512(message).then((hash) => setResult(hash));
        } else {
            setResult(undefined);
        }
    }, [message]);
    return result;
};

const useToggle = (featureToggle: FeatureToggle): FeatureToggleResponse<unknown> => {
    const idToken = useAppSelector(getIdToken);
    const dunsFromPath = useDunsNumberFromPath() ?? '';
    const hashedEmailAddress = useSha512HashedString(idToken?.email);
    const userObject = idToken
        ? new User(idToken.sub, idToken.email, idToken.locale, {
              duns: dunsFromPath,
              sha512Mail: hashedEmailAddress ?? '',
              accountId: idToken.account ?? '',
          })
        : new User(IDENTIFIER_FOR_UNKNOWN_USERS);
    const defaultValue = defaultValues[featureToggle];
    const { value, loading } = useFeatureFlag(featureToggle, defaultValue, userObject);
    return {
        value: loading ? undefined : value,
        defaultValue,
        isLoading: loading,
    };
};

export const useGetLatestVersionNumber = (): FeatureToggleResponse<number> =>
    useToggle(FeatureToggle.WEBEDI_REFRESH_VERSION_NUMBER) as FeatureToggleResponse<number>;

export const useDiscoveryNextGenFeatures = (): FeatureToggleResponse<boolean> =>
    useToggle(FeatureToggle.DISCOVERY_NEXT_GEN_FEATURES) as FeatureToggleResponse<boolean>;

export const useWip = (): FeatureToggleResponse<boolean> =>
    useToggle(FeatureToggle.WORK_IN_PROGRESS) as FeatureToggleResponse<boolean>;

export const useMaintenanceWindow = (): FeatureToggleResponse<string> =>
    useToggle(FeatureToggle.MAINTENANCE_WINDOW) as FeatureToggleResponse<string>;
