import { ApplicationLayoutBodyNavigation } from '@rio-cloud/rio-uikit';
import SubNavigation from '@rio-cloud/rio-uikit/SubNavigation';
import { FormattedMessage } from 'react-intl';
import { NavLink, useLocation } from 'react-router-dom';
import { IdentityProvider, config } from '../../../config';
import { TranslationKeys } from '../../../configuration/lang/types';
import { useAppDispatch } from '../../../configuration/setup/typedReduxHooks';
import { DISPATCH_PROPOSALS_TAG, DISPATCH_PROPOSAL_PROBLEMS_TAG } from '../api/baseApi';
import { dispatchProposalApi } from '../api/dispatchProposal/dispatchProposalApi';
import { Routing } from './routing/Routes';
const createNavLink = (
    dunsNumber: string | undefined,
    path: string,
    translationForLabel: TranslationKeys,
    exact?: boolean,
    additionalOnClick?: () => void,
) => {
    const basePath = `${Routing.webScm}/${dunsNumber}`;

    return (
        <NavLink to={`${basePath}${path}`} end={exact} onClick={additionalOnClick ?? (() => {})}>
            <FormattedMessage id={translationForLabel} />
        </NavLink>
    );
};

export const homeLink = <NavLink to={'/'} />;
export const useNavLinks = (dunsNumber: string | undefined, webSCMPlusFeaturesAreActive: boolean) => {
    const isRioEnvironment = config.identityProvider === IdentityProvider.RIO;
    const dispatch = useAppDispatch();

    const deliverySchedulesLink = {
        key: 'delivery-schedules',
        route: createNavLink(dunsNumber, `${Routing.deliverySchedules}`, 'webedi.sublink.deliverySchedules'),
    };
    const suggestionsLink = {
        key: 'suggestions',
        route: createNavLink(dunsNumber, `${Routing.suggestions}`, 'webedi.sublink.suggestionsV2'),
    };
    const shipmentsLink = {
        key: 'shipments',
        route: createNavLink(dunsNumber, `${Routing.shipments}`, 'webedi.sublink.shipments'),
    };
    const exportedShipmentsLink = {
        key: 'exported-shipments',
        route: createNavLink(dunsNumber, `${Routing.exportedShipments}`, 'webedi.sublink.exportedShipments'),
    };
    const carriersLink = {
        key: 'carriers',
        route: createNavLink(dunsNumber, `${Routing.carriers}`, 'webedi.sublink.carriers'),
    };

    const dispatchProposalsLink = {
        key: 'dispatch-proposals',
        route: createNavLink(
            dunsNumber,
            `${Routing.dispatchProposals}`,
            'webedi.sublink.dispatchProposals',
            undefined,
            () => {
                dispatch(
                    dispatchProposalApi.util?.invalidateTags([DISPATCH_PROPOSALS_TAG, DISPATCH_PROPOSAL_PROBLEMS_TAG]),
                );
            },
        ),
    };
    const transportOrdersLink = {
        key: 'transport-orders',
        route: createNavLink(dunsNumber, `${Routing.transportOrders}`, 'webedi.sublink.transportOrders'),
    };

    const dunsSelectionLink = {
        key: 'duns-selection',
        route: (
            <NavLink to={Routing.dunsSelection}>
                <FormattedMessage id={'webedi.sublink.dunsSelection'} />
            </NavLink>
        ),
    };

    const adminLink = {
        key: 'administration',
        route: createNavLink(dunsNumber, `${Routing.admin}`, 'webedi.sublink.administration'),
    };

    if (dunsNumber && webSCMPlusFeaturesAreActive) {
        return [
            deliverySchedulesLink,
            dispatchProposalsLink,
            transportOrdersLink,
            suggestionsLink,
            shipmentsLink,
            exportedShipmentsLink,
            adminLink,
        ];
    } else if (dunsNumber) {
        return [
            deliverySchedulesLink,
            suggestionsLink,
            shipmentsLink,
            exportedShipmentsLink,
            isRioEnvironment ? adminLink : carriersLink,
        ];
    } else {
        return [dunsSelectionLink];
    }
};

export const useSubNavigation = (dunsNumber: string | undefined, webSCMPlusFeaturesAreActive: boolean) => {
    const isRioIDP = config.identityProvider === IdentityProvider.RIO;
    const isVwQaIDP = config.identityProvider === IdentityProvider.VW_QA;
    const location = useLocation();

    if (dunsNumber && location.pathname.indexOf(`${Routing.admin}`) > -1) {
        const carriersLink = {
            key: 'carriers',
            route: createNavLink(dunsNumber, `${Routing.admin}${Routing.carriers}`, 'webedi.sublink.carriers'),
        };
        const loadingLocationsLink = {
            key: 'loading-locations',
            route: createNavLink(
                dunsNumber,
                `${Routing.admin}${Routing.loadingLocations}`,
                'webedi.sublink.loadingLocations',
            ),
        };
        const enableClosingDays = localStorage.getItem('enableClosingDays');
        const closingDaysLink = {
            key: 'closing-days',
            route: createNavLink(dunsNumber, `${Routing.admin}${Routing.closingDays}`, 'webedi.sublink.closingDays'),
        };
        const notificationsLink = {
            key: 'notifications',
            route: createNavLink(
                dunsNumber,
                `${Routing.admin}${Routing.notifications}`,
                'webedi.sublink.notifications',
            ),
        };

        const subNavItems = [
            ...(webSCMPlusFeaturesAreActive ? [loadingLocationsLink] : []),
            carriersLink,
            ...(webSCMPlusFeaturesAreActive && (isVwQaIDP || enableClosingDays) ? [closingDaysLink] : []),
            ...(isRioIDP ? [notificationsLink] : []),
        ];
        return (
            <ApplicationLayoutBodyNavigation>
                <SubNavigation navItems={subNavItems} />
            </ApplicationLayoutBodyNavigation>
        );
    }
    return undefined;
};

export const useClosingDaysPageActive = () => {
    const { pathname } = useLocation();
    return pathname.includes(`${Routing.admin}${Routing.closingDays}`);
};
